import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'

type GraphQLEdges = Array<{
  node: { absolutePath: string; childImageSharp: IGatsbyImageData }
}>

export function photoFor(edges: GraphQLEdges, filename: string) {
  const edge = edges.find(edge => edge?.node?.absolutePath?.endsWith(filename))
  const image = edge?.node?.childImageSharp
  if (image && edge) {
    return getImage(edge.node.childImageSharp)
  } else {
    throw new Error(`Cannot find image for ${filename}`)
  }
}
