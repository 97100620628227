import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import Stars from '../assets/images/about-us-stars.png'
import Tag from '../assets/images/about-us-tag.svg'
import HeroBlock from '../components/blocks/HeroBlock'
import { G2AndCustomerLogosDark } from '../components/G2AndCustomerLogosDark'
import Layout from '../components/Layout'
import Block from '../components/primitives/Block'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import Br from '../components/system/Br'
import { DarkAnnouncementBadge } from '../components/system/DarkAnnouncementBadge'
import TeamMember from '../components/TeamMember'
import { photoFor } from '../imageUtils'
import { ThemeColor } from '../styles/theme'

const members = [
  {
    name: 'Otto Hilska',
    title: 'Founder & CEO',
    photo: 'otto.jpg',
    linkedin: 'https://www.linkedin.com/in/hilska',
  },
  {
    name: 'Mika Vakula',
    title: 'Software Engineer, Team Lead',
    linkedin: 'https://www.linkedin.com/in/mikavaisanen',
    photo: 'mika.jpg',
  },
  {
    name: 'Oskari Virtaoja',
    title: 'Head of Engineering',
    photo: 'oskari.jpg',
    linkedin: 'https://www.linkedin.com/in/oskarivirtanen',
  },
  {
    name: 'Roman Musatkin',
    title: 'Head of Product & Design',
    photo: 'roman.jpg',
    linkedin: 'https://www.linkedin.com/in/musatkin',
  },
  {
    name: 'Jarno Rantanen',
    title: 'Software Engineer, Team Lead',
    linkedin: 'https://www.linkedin.com/in/jarnorantanen',
    photo: 'jarno.jpg',
  },
  {
    name: 'Eero Kettunen',
    title: 'Customer Success Manager',
    linkedin: 'https://www.linkedin.com/in/eero-kettunen-131b8688/',
    photo: 'eero.jpg',
  },
  {
    name: 'Aleksi Heinonen',
    title: 'Head of Finance & Operations',
    linkedin: 'https://www.linkedin.com/in/aleksiheinonen/',
    photo: 'aleksi.jpg',
  },
  {
    name: 'Lauri Ikonen',
    title: 'Product Manager',
    linkedin: 'https://www.linkedin.com/in/ikonenlauri/',
    photo: 'lauri.jpg',
  },
  {
    name: 'Kimmo Brunfeldt',
    title: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/kimmobrunfeldt/',
    photo: 'kimmo.jpg',
  },
  {
    name: 'Ari-Pekka Koponen',
    title: 'Head of Platform',
    linkedin: 'https://www.linkedin.com/in/apkoponen/',
    photo: 'ari-pekka.jpg',
  },
  {
    name: 'Petri Avikainen',
    title: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/pmavik',
    photo: 'petri.jpg',
  },
  {
    name: 'Eemeli Kantola',
    title: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/eemeli-kantola',
    photo: 'eemeli.jpg',
  },
  {
    name: 'Pinja Dodik',
    title: 'Head of Marketing',
    linkedin: 'https://www.linkedin.com/in/pinjadodik/',
    photo: 'pinja.jpg',
  },
  {
    name: 'Hugo Kiiski',
    title: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/hugokiiski/',
    photo: 'hugo.jpg',
  },
  {
    name: 'Miikka Jokela',
    title: 'Revenue Operations Manager',
    linkedin: 'https://www.linkedin.com/in/miikkajokela/',
    photo: 'miikka.jpg',
  },
  {
    name: 'Sayo Oladeji',
    title: 'Software Engineer, Team Lead',
    linkedin: 'https://www.linkedin.com/in/oluwasayo/',
    photo: 'sayo.jpg',
  },
  {
    name: 'Oskari Kallio',
    title: 'Lead Brand Designer',
    linkedin: 'https://www.linkedin.com/in/oskari-kallio-13940a19a/',
    photo: 'oskarik.jpg',
  },
  {
    name: 'Rebecca Murphey',
    title: 'Field CTO',
    linkedin: 'https://www.linkedin.com/in/rmurphey/',
    photo: 'rebecca.png',
  },
  {
    name: 'Kimmo Puputti',
    title: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/kimmopuputti/',
    photo: 'kimmo-puputti.jpg',
  },
  {
    name: 'Akseli Aho',
    title: 'Customer Success Manager',
    linkedin: 'https://www.linkedin.com/in/akseliaho/',
    photo: 'akseli.jpeg',
  },
  {
    name: 'Avik Dey',
    title: 'Product Designer',
    photo: 'avik.jpg',
    linkedin: 'https://www.linkedin.com/in/deyavik',
  },
  {
    name: 'Alexi Ortega',
    title: 'Customer Success Manager',
    photo: 'alexi.jpg',
    linkedin: 'https://www.linkedin.com/in/alexi-ortega/',
  },
  {
    name: 'Niko Salmela',
    title: 'Software Engineer',
    photo: 'niko.jpg',
    linkedin: 'https://www.linkedin.com/in/niko-salmela-041983a3/',
  },
  {
    name: 'Julien Chakra',
    title: 'Software Engineer',
    photo: 'julien.jpg',
    linkedin: 'https://www.linkedin.com/in/julien-chakra-33793029/',
  },
  {
    name: 'Miikka Holkeri',
    title: 'Product Manager',
    linkedin: 'https://www.linkedin.com/in/holkeri/',
    photo: 'miikka-holkeri.jpeg',
  },
  {
    name: 'Nathan Dao',
    title: 'Software Engineer',
    photo: 'nathan.jpeg',
    linkedin: 'https://www.linkedin.com/in/nathandao/',
  },
  {
    name: 'Liv Læssøe',
    title: 'Product Designer',
    linkedin: 'https://www.linkedin.com/in/lilas/',
    photo: 'liv.png',
  },
  {
    name: 'Mikko Porkola',
    title: 'Software Engineer',
    photo: 'mikko.jpeg',
    linkedin: 'https://www.linkedin.com/in/mikkoporkola/',
  },
  {
    name: 'Dima Egorov',
    title: 'Software Engineer',
    photo: 'dima.png',
    linkedin: 'https://www.linkedin.com/in/persistent/',
  },
  {
    name: 'Eero Tuhka',
    title: 'Software Engineer, Team Lead',
    photo: 'eero-tuhka.jpg',
    linkedin: 'https://www.linkedin.com/in/eero-tuhka/',
  },
  {
    name: 'Kaarlo Kock',
    title: 'Software Engineer',
    photo: 'kaarlo.jpg',
    linkedin: 'https://www.linkedin.com/in/kaarlo-kock-161771269/',
  },
  {
    name: 'Jani Niemelä',
    title: 'Software Engineer',
    photo: 'jani.jpg',
    linkedin: 'https://www.linkedin.com/in/siquel/',
  },
  {
    name: 'Santeri Toivanen',
    title: 'Marketing Manager',
    photo: 'santeri.jpeg',
    linkedin: 'https://www.linkedin.com/in/santeritoivanen/',
  },
  {
    name: 'First Sutham',
    title: 'Software Engineer',
    photo: 'first.jpg',
    linkedin: 'https://www.linkedin.com/in/first-sutham',
  },
  {
    name: 'Francesco Perri',
    title: 'Account Executive',
    linkedin: 'https://www.linkedin.com/in/fnper',
    photo: 'francesco.jpg',
  },
  {
    name: 'Juhana Nurmio',
    title: 'Product Manager',
    photo: 'juhana.jpg',
    linkedin: 'https://www.linkedin.com/in/juhananurmio/',
  },
  {
    name: 'Bill Rufo',
    title: 'VP of Sales & Customer Success',
    photo: 'bill.jpg',
    linkedin: 'https://www.linkedin.com/in/billrufo/',
  },
  {
    name: 'Jess Wolfe',
    title: 'Customer Success Manager',
    linkedin: 'https://www.linkedin.com/in/thejessicawolfe/',
    photo: 'jess.png',
  },
  {
    name: 'Connor Farrell',
    title: 'Account Executive',
    linkedin: 'https://www.linkedin.com/in/connorjfarrell/',
    photo: 'connor.jpeg',
  },
  {
    name: 'Markus Koljonen',
    title: 'Customer Success Manager',
    linkedin: 'https://www.linkedin.com/in/markuskoljonen/',
    photo: 'markus.jpg',
  },
  {
    name: 'Henrik Skogström',
    title: 'Product Designer',
    linkedin: 'https://www.linkedin.com/in/skogstrom/',
    photo: 'henrik.jpg',
  },
  {
    name: 'Pietu Roisko',
    title: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/roisko/',
    photo: 'pietu.jpg',
  },
  {
    name: 'Eric Harrington',
    title: 'Enterprise Account Executive',
    linkedin: 'https://www.linkedin.com/in/ericharrington/',
    photo: 'eric.jpg',
  },
  {
    name: 'Erin Backlund',
    title: 'Content Marketing Manager',
    linkedin: 'https://www.linkedin.com/in/erinmcgee24/',
    photo: 'erin.png',
  },
]

const AboutUsPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      justifyCenter
      title="About us"
      description="Swarmia is a software engineering intelligence platform for modern organizations. Learn more about our philosophy and the people behind Swarmia."
      isNew
      variant="dark"
    >
      <HeroBlock
        tag={Tag}
        title={
          <>
            Swarmia shapes the way
            <Br /> great software is made
          </>
        }
        backgroundImage={data.backgroundImage.childImageSharp.gatsbyImageData}
        customCta={<div />}
      />
      <Stack backgroundColor={'#0E0D33' as ThemeColor} paddingBottom={92}>
        <Block maxWidth="mediumBlock" paddingTop={16} paddingBottom={64}>
          <Box.img src={Stars} alt="" width={40} height={40} />
          <Row
            space={32}
            color="white"
            paddingTop={8}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box.h2 font="h3" flexGrow={0} flexShrink={1} width={350}>
              Designed for the best software organizations in the world
            </Box.h2>
            <Box font="medium" flex={1} maxWidth={520} className="links-dark">
              When we started building Swarmia in 2019, we took on a pragmatic
              and principled approach to improving developer productivity. We
              knew that a simple dashboard wouldn’t help software organizations
              drive concrete change. We also knew that to make any change stick,
              the platform would need to be built not only for leaders but also
              for developers.
              <br />
              <br />
              We partnered with some of the best software companies in the world
              to make sure Swarmia would surface insights they could
              systematically apply across all levels of the organization. Today,
              Swarmia is a comprehensive software engineering intelligence
              platform that combines the three perspectives of engineering
              effectiveness: business outcomes, developer productivity, and
              developer experience.
              <br />
              <br />
              Over the years, we’ve been recognized as a market leader by G2,
              been included in Gartner and Forrester research — and even{' '}
              <Link to="/build/">
                written a book about building effective software organizations
              </Link>
              . We’re lucky to continue working with a growing number of the
              world’s best software organizations, from small startups to
              Fortune 500 companies.
            </Box>
          </Row>
        </Block>

        <Block maxWidth="full" paddingY={64} color="white">
          <Box.h2
            font="h3"
            textAlign="center"
            maxWidth={470}
            marginX="auto"
            paddingBottom={64}
          >
            Trusted by modern engineering organizations from startups to
            enterprises
          </Box.h2>
          <G2AndCustomerLogosDark />
        </Block>

        <Block paddingY={64} maxWidth="mediumBlock" color="white">
          <Row space={8} justifyContent="flex-start" alignItems="center">
            <Box.h2 font="h3">Built by an experienced team</Box.h2>
            <DarkAnnouncementBadge text="We're hiring" href="/careers/" />
          </Row>
          <Box
            font="medium"
            paddingTop={32}
            maxWidth={620}
            className="links-dark"
          >
            We’ve previously been a part of building companies like Stripe,
            Atlassian, Meta, and Pluralsight. Today, we have team members in New
            York, Boston, Denver, and Helsinki.
          </Box>
          <Box
            paddingTop={64}
            display="grid"
            gridGap={16}
            gridColumnGap={{ xs: 32, md: 64 }}
            gridRowGap={16}
            gridTemplateColumns={{
              xs: 'repeat(1, minmax(0, auto))',
              xsm: 'repeat(2, minmax(0, auto))',
              sm: 'repeat(3, minmax(0, auto))',
              lg: 'repeat(4, minmax(0, auto))',
            }}
            justifyContent="center"
            alignItems="center"
            marginX="auto"
          >
            {members.map((member, idx) => (
              <Box key={`${idx}-${member.name}`}>
                <TeamMember
                  {...member}
                  photo={photoFor(data.teamImages.edges, member.photo)}
                />
              </Box>
            ))}
          </Box>
        </Block>

        {false && (
          <Block paddingY={64} maxWidth="mediumBlock" color="white">
            <Box.h2 font="h3">Backed by exceptional investors</Box.h2>
            <Box
              font="medium"
              paddingTop={32}
              paddingBottom={64}
              maxWidth={620}
              className="links-dark"
            >
              We’re backed by well-known venture capital firms as well as a
              handful of forward-thinking founders and makers.
            </Box>
            {/*<Investors />*/}
          </Block>
        )}

        {false && (
          <Block
            paddingTop={64}
            maxWidth="mediumBlock"
            color="white"
            width="100%"
          >
            <Box.h2 font="h5">Featured in media</Box.h2>
            <Stack space={24} paddingTop={32}>
              {[
                {
                  title:
                    'Refactoring podcast  |  Elements of an Effective Software Organization with Rebecca Murphey',
                  url: 'https://refactoring.fm/p/elements-of-an-effective-software/',
                },
                {
                  title:
                    'TechCruch  |  Swarmia raises $8M Seed to help software development teams deal with data',
                  url: 'https://techcrunch.com/2021/05/12/swarmia-raises-8m-seed-to-help-software-development-teams-deal-with-data/',
                },
                {
                  title:
                    'Boss Level podcast  |  Otto Hilska: When you don’t know what to do with life, start a company',
                  url: 'https://podcasts.apple.com/fi/podcast/otto-hilska-when-you-dont-know-what-to-do-with-your/id1041885043?i=1000558214240',
                },
              ].map(link => (
                <Box key={link.title}>
                  <Box.a
                    color="white"
                    font="medium"
                    fontWeight={400}
                    href={link.url}
                    textDecoration="underline"
                    css={`
                      text-underline-position: from-font;

                      &:hover {
                        opacity: 0.7;
                        color: white;
                      }
                    `}
                  >
                    {link.title}
                  </Box.a>{' '}
                  ↗
                </Box>
              ))}
            </Stack>
          </Block>
        )}
      </Stack>
    </Layout>
  )
}

const query = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "images/about-us-hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    teamImages: allFile(filter: { absolutePath: { regex: "/images/team//" } }) {
      edges {
        node {
          absolutePath
          childImageSharp {
            gatsbyImageData(
              width: 120
              height: 120
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`

export default AboutUsPage
