import React from 'react'
import g2badge from '../assets/images/G2Badge.svg'
import Box from './primitives/Box'
import Row from './primitives/Row'

import Gorgias from '../assets/images/customers/white-wall/gorgias.svg'
import Miro from '../assets/images/customers/white-wall/miro.svg'
import Pleo from '../assets/images/customers/white-wall/pleo.svg'
import Truelayer from '../assets/images/customers/white-wall/truelayer.svg'
import Webflow from '../assets/images/customers/white-wall/webflow.svg'
import Aiven from '../assets/images/customers/white-wall/aiven.svg'
import Docker from '../assets/images/customers/white-wall/docker.svg'
import Trustpilot from '../assets/images/customers/white-wall/trustpilot.svg'

const companies = [
  { name: 'Docker', image: Docker },
  { name: 'Aiven', image: Aiven },
  { name: 'Webflow', image: Webflow },
  { name: 'Trustpilot', image: Trustpilot },

  { name: 'Gorgias', image: Gorgias },
  { name: 'Miro', image: Miro },
  { name: 'Pleo', image: Pleo },
  { name: 'Truelayer', image: Truelayer },
]

export const G2AndCustomerLogosDark = () => {
  return (
    <Row
      space={{ xs: 32, md: 64 }}
      flexDirection={{ xs: 'column-reverse', sm: 'row' }}
      alignItems="center"
      justifyContent="center"
    >
      <G2Badge />
      <Box
        height={{ xs: 3.5, sm: 180 }}
        width={{ xs: '100%', sm: 3.5 }}
        backgroundColor="white"
        opacity={0.1}
        borderRadius={4}
      />
      <Box
        display="grid"
        gridRowGap={24}
        gridTemplateColumns={{
          xs: 'repeat(2, minmax(0, auto))',
          md: 'repeat(4, minmax(0, auto))',
        }}
        justifyContent="center"
        alignItems="center"
      >
        {companies.map(company => {
          return (
            <Box
              key={company.name}
              display="flex"
              flex={1}
              width={200}
              maxWidth="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Box.img src={company.image} alt={company.name} maxWidth="100%" />
            </Box>
          )
        })}
      </Box>
    </Row>
  )
}

function G2Badge() {
  return <img width="128" src={g2badge} alt="G2 Leader Fall 2023" />
}
