import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from 'react'
import LinkedIn from '../assets/images/social-icons/linkedin-icon.png'
import Box from './primitives/Box'
import Row from './primitives/Row'
import Stack from './primitives/Stack'

export function TeamMember({ name, title, photo, linkedin }) {
  return (
    <Row space={16}>
      <Box
        borderRadius={9999}
        width={28}
        height={28}
        isolation="isolate"
        overflow="hidden"
        flexShrink={0}
      >
        <GatsbyImage image={photo} alt={name} className="portrait" />
      </Box>
      <Stack gap={0} color="white" font="small" fontSize={14}>
        <Row space={10} alignItems="center">
          <Box fontWeight={700} whiteSpace="nowrap">
            {name}
          </Box>
          {linkedin ? (
            <Box.a href={linkedin} target="_blank">
              <Box.img
                display="block"
                width={16}
                height={16}
                src={LinkedIn}
                alt="LinkedIn Logo"
              />
            </Box.a>
          ) : null}
        </Row>
        <Box color="secondary" minHeight={60}>
          {title}
        </Box>
      </Stack>
    </Row>
  )
}

TeamMember.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  photo: PropTypes.any,
  bio: PropTypes.string,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
}

export default TeamMember
